import Slider, { createSliderWithTooltip } from 'rc-slider';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import 'rc-slider/assets/index.css';

import Checkbox from '../Input/Checkbox';
import { useInterval } from '../../utils/helpers/React';
import {
  Sparklines,
  SparklinesLine,
} from 'react-sparklines';
import './style.scss';

const SliderWithTooltip = createSliderWithTooltip(Slider);

function BatteryState({ getBatteryState }) {
  const { t } = useTranslation('common');

  const cellLimit = 3.7;
  const [batteryState, setBatteryState] = useState({
    text: null,
    danger: false,
  });

  /* istanbul ignore next */
  useInterval(async() => {
    if(getBatteryState) {
      const state = await getBatteryState();

      if(state && state.cellCount > 0) {
        const danger = (state.voltage / state.cellCount) < cellLimit;
        setBatteryState({
          text: `${state.cellCount}S @ ${state.voltage}V`,
          danger,
        });
      } else {
        setBatteryState({
          text: 'N/A',
          danger: false,
        });
      }
    }
  }, 1000);

  if(batteryState.text) {
    return (
      <span className={`battery-state ${batteryState.danger ? 'danger' : ''}`}>
        {`${t('battery')} ${batteryState.text}`}
      </span>
    );
  }

  return null;
}
BatteryState.propTypes = { getBatteryState: PropTypes.func.isRequired };

function MotorSlider({
  disabled,
  max,
  min,
  onChange,
  startValue,
}) {
  const [value, setValue] = useState(startValue);

  /* istanbul ignore next */
  const update = useCallback((value) => {
    setValue(value);
    onChange(value);
  }, [onChange]);

  return(
    <SliderWithTooltip
      defaultValue={value}
      disabled={disabled}
      max={max}
      min={min}
      onChange={update}
      step={10}
      tipProps={{
        visible: true,
        placement: 'top',
      }}
    />
  );
}
MotorSlider.propTypes = {
  disabled: PropTypes.bool.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  startValue: PropTypes.number.isRequired,
};

function IndividualMotorSlider({
  disabled,
  index,
  max,
  min,
  onChange,
  showDebug,
  startValue,
  telem,
}) {
  const { t } = useTranslation('common');
  const [rpms, setRpms] = useState([]);
  const [currents, setCurrents] = useState([]);

  /* istanbul ignore next */
  const update = useCallback((value) => {
    onChange(index + 1, value);
  }, [index, onChange]);

  function getTelem(){
    if (telem == null) {
      return "";
    }
    return `<span class="motor-telem-info">
<span class="rpm">${telem.rpm}</span> rpm 
${telem.escTemperature} degrees 
${showDebug ? " | " + telem.escCurrent : ""}
</span>`;
  }

  useEffect(() => {
    if(telem != null) {
      let r = rpms;
      if(r.length > 50) {
        r.shift();
      }
      r.push(telem.rpm);
    }

    if(telem != null) {
      let r = currents;
      if(showDebug){        
        if(r.length > 50) {
          r.shift();
        }
        r.push(telem.escCurrent);
      }else{
        r.splice(0, r.length);
      }
    }

  }, [telem, rpms,  showDebug, currents]);


  return(
    <div 
      className={`slider slider-${index}`}
      style={{ flex:"0 0 350px" }}
    >
      <h3 dangerouslySetInnerHTML={{ __html: t("motorNr", { index: index + 1 }) + getTelem() }} />

      {rpms && rpms.length > 0 &&
        <Sparklines 
          data={rpms} 
        >
          <SparklinesLine />
        </Sparklines>}
      
      {currents && currents.length > 0 &&
        <Sparklines        
          data={currents} 
          max={255}
          min={0}
        >
          <SparklinesLine color="red" />
        </Sparklines>}
      
      {rpms.length === 0 &&
        <div className='paddingbox' />}

      <MotorSlider
        disabled={disabled}
        max={max}
        min={min}
        onChange={update}
        startValue={startValue}
      />
    </div>
  );
}
IndividualMotorSlider.propTypes = {
  disabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  showDebug: PropTypes.bool.isRequired,
  startValue: PropTypes.number.isRequired,
  telem: PropTypes.shape({
    rpm:PropTypes.number,
    invalidPct:PropTypes.number,
    escTemperature:PropTypes.number,
    escVoltage:PropTypes.number,
    escCurrent:PropTypes.number,
    escConsumption:PropTypes.number,
  }),
};
IndividualMotorSlider.defaultProps = { telem: null };
function MotorControl({
  getBatteryState,
  motorCount,
  onAllUpdate,
  onEnableMotorDebug,
  onSingleUpdate,
  startValue,
  getMotorTelem,
}) {
  const { t } = useTranslation('common');

  const minValue = 1000;
  const maxValue = 2000;

  const [unlock, setUnlock] = useState(false);
  const [unlockIndividual, setUnlockIndividual] = useState(true);

  const [motorDebug, setMotorDebug] = useState(false);
  const [telems, setTelems] = useState([]);

  useInterval(async() => {
    if(getMotorTelem) {
      const state = await getMotorTelem();
      if(state) {
        setTelems(state);
      } else {
        setTelems(null);
      }
    }
  }, 100);


  const toggleUnlock = useCallback(() => {
    setUnlock(!unlock);
    onAllUpdate(startValue);
  }, [unlock, startValue, onAllUpdate]);


  const toggleMotorDebug = useCallback(async () => {
    setMotorDebug(!motorDebug);
  }, [motorDebug]);

  useEffect(() => {
    if(onEnableMotorDebug){
      onEnableMotorDebug(motorDebug);
    }
  }, [onEnableMotorDebug,motorDebug]);
  
  
  // Makes no sense to test, component has its own test, we just assume that
  // the slider actually slides.
  /* istanbul ignore next */
  const updateValue = useCallback((value) => {
    if(value !== startValue && unlockIndividual) {
      setUnlockIndividual(false);
    }

    if(value === startValue) {
      setUnlockIndividual(true);
    }

    onAllUpdate(value);
  }, [startValue, unlockIndividual, onAllUpdate]);

  /* istanbul ignore next */
  const updateSingleValue = useCallback((index, speed) => {
    onSingleUpdate(index, speed);
  }, [onSingleUpdate]);

  const singleSliderElements = [];
  for(let i = 0; i < motorCount; i += 1) {
    singleSliderElements.push(
      <IndividualMotorSlider
        disabled={!unlock || !unlockIndividual}
        index={i}
        key={i}
        max={maxValue}
        min={minValue}
        onChange={updateSingleValue}
        showDebug={motorDebug}
        startValue={startValue}
        telem={telems && i < telems.length ? telems[i] : null}
      />
    );
  }

  const memoizedMasterSlider = useMemo(() => (
    <MotorSlider
      disabled={!unlock}
      max={maxValue}
      min={minValue}
      onChange={updateValue}
      startValue={startValue}
    />
  ), [startValue, unlock, updateValue]);

  return (
    <div id="motor-control-wrapper">
      <div className="gui-box grey">
        <div className="gui-box-titlebar">
          <div className="spacer-box-title">
            {t('motorControl')}
          </div>
        </div>

        <div className="spacer-box">
          <div>
            <p>
              { t('motorControlTextLine1') }
            </p>

            <p>
              { t('motorControlTextLine2') }
            </p>

            <p>
              { t('motorControlTextLine3') }
            </p>
          </div>

          <div className="row-wrapper">
            <div className="column-wrapper">
              <Checkbox
                label={t('enableMotorControl')}
                name="enable-motor-control"
                onChange={toggleUnlock}
                value={unlock ? 1 : 0}
              />
            </div>
            
            <div className="column-wrapper">
              <BatteryState
                getBatteryState={getBatteryState}
              />
            </div>

            <div className="column-wrapper">
              <Checkbox
                label={t('enableMotorDebug')}
                name="enable-motor-debug"
                onChange={toggleMotorDebug}
                value={motorDebug ? 1 : 0}
              />
            </div>
          </div>

          <div 
            className="column-wrapper"
            style={{ 
              flexFlow:"wrap", 
              justifyContent: "space-between",
            }} 
          >
              <div 
                id="master-slider"
                style={{ flex:"3 0 100%" }}
              >
                <h3
                  style={{ marginBottom: "30px" }}
                >
                  {t('masterSpeed')}
                </h3>
                
                {memoizedMasterSlider}
              </div>
              
              {singleSliderElements}
          </div>
        </div>
      </div>
    </div>
  );
}
MotorControl.defaultProps = {
  motorCount: 0,
  startValue: 1000,
};
MotorControl.propTypes = {
  getBatteryState: PropTypes.func.isRequired,
  getMotorTelem: PropTypes.func.isRequired,
  motorCount: PropTypes.number,
  onAllUpdate: PropTypes.func.isRequired,
  onEnableMotorDebug: PropTypes.func.isRequired,
  onSingleUpdate: PropTypes.func.isRequired,
  startValue: PropTypes.number,
};

export default MotorControl;
