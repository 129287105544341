const BOOT_LOADER_PINS = {
  HF32PA2: 0x82,
  HF32PB4: 0x94,
  HF32PA0: 0x80,
};

const RESET_DELAY_MS = 2500;
const LAYOUT_SIZE = 208;

const BOOT_LOADER_VERSION_OFFSET = 0x00C0;
const BOOT_LOADER_VERSION_SIZE = 1;

const LAYOUT = {
  BOOT_BYTE: {
    offset: 0x00,
    size: 1,
  },
  LAYOUT_REVISION: {
    offset: 0x01,
    size: 1,
  },
  BOOT_LOADER_REVISION: {
    offset: 0x02,
    size: 1,
  },
  MAIN_REVISION: {
    offset: 0x03,
    size: 1,
  },
  SUB_REVISION: {
    offset: 0x04,
    size: 1,
  },
  NAME: {
    offset: 0x05,
    size: 12,
  },
  MOTOR_DIRECTION: {
    offset: 0x11,
    size: 1,
  },
  BIDIRECTIONAL_MODE: {
    offset: 0x12,
    size: 1,
  },
  SINUSOIDAL_STARTUP: {
    offset: 0x13,
    size: 1,
  },
  COMPLEMENTARY_PWM: {
    offset: 0x14,
    size: 1,
  },
  VARIABLE_PWM_FREQUENCY: {
    offset: 0x15,
    size: 1,
  },
  STUCK_ROTOR_PROTECTION: {
    offset: 0x16,
    size: 1,
  },
  TIMING_ADVANCE: {
    offset: 0x17,
    size: 1,
  },
  PWM_FREQUENCY: {
    offset: 0x18,
    size: 1,
  },
  STARTUP_POWER: {
    offset: 0x19,
    size: 1,
  },
  MOTOR_KV: {
    offset: 0x1a,
    size: 1,
  },
  MOTOR_POLES: {
    offset: 0x1b,
    size: 1,
  },
  BRAKE_ON_STOP: {
    offset: 0x1c,
    size: 1,
  },
  STALL_PROTECTION: {
    offset: 0x1d,
    size: 1,
  },
  BEEP_VOLUME: {
    offset: 0x1e,
    size: 1,
  },
  INTERVAL_TELEMETRY:{
    offset: 0x1f,
    size: 1,
  },
  SERVO_LOW_THRESHOLD: {
    offset: 0x20,
    size: 1,
  },
  SERVO_HIGH_THRESHOLD: {
    offset: 0x21,
    size: 1,
  },
  SERVO_NEUTRAL: {
    offset: 0x22,
    size: 1,
  },
  SERVO_DEAD_BAND: {
    offset: 0x23,
    size: 1,
  },
  LOW_VOLTAGE_CUTOFF: {
    offset: 0x24,
    size: 1,
  },
  LOW_VOLTAGE_THRESHOLD: {
    offset: 0x25,
    size: 1,
  },
  RC_CAR_REVERSING: {
    offset: 0x26,
    size: 1,
  },
  USE_HALL_SENSORS: {
    offset: 0x27,
    size: 1,
  },
  SINE_MODE_RANGE: {
    offset: 0x28,
    size: 1,
  },
  BRAKE_STRENGTH: {
    offset: 0x29,
    size: 1,
  },
  ESC_PROTOCOL: {
    offset: 0x2E,
    size: 1,
  },
  RAMP_SPEED: {
    offset: 0x2F,
    size: 1,
  },
  STARTUP_MELODY: {
    offset: 0x30,
    size: 128,
  },
  DEVICE_ID: {
    offset: 176,
    size: 12,
  },
  DEVICE_KEY: {
    offset: 188,
    size: 16,
  },
  DUTY_RAMP_DOWN: {
    offset: 204,
    size: 1,
  },
  BAT_CELL_COUNT: {
    offset: 205,
    size: 1,
  },
};

const EEPROM = {
  LAYOUT,
  LAYOUT_SIZE,
  NAMES: [''],
  RESET_DELAY: RESET_DELAY_MS,
  BOOT_LOADER_OFFSET: BOOT_LOADER_VERSION_OFFSET,
  BOOT_LOADER_SIZE: BOOT_LOADER_VERSION_SIZE,
  BOOT_LOADER_PINS,
};

export default EEPROM;

/*
0："BOOT_BYTE"
1："LAYOUT_REVISION"
2："BOOT_LOADER_REVISION"
3："MAIN_REVISION"
4："SUB_REVISION"
5："NAME"
17："MOTOR_DIRECTION"
18："BIDIRECTIONAL_MODE"
19："SINUSOIDAL_STARTUP"
20："COMPLEMENTARY_PWM"
21："VARIABLE_PWM_FREQUENCY"
22："STUCK_ROTOR_PROTECTION"
23："TIMING_ADVANCE"
24："PWM_FREQUENCY"
25："STARTUP_POWER"
26："MOTOR_KV"
27："MOTOR_POLES"
28："BRAKE_ON_STOP"
29："STALL_PROTECTION"
30："BEEP_VOLUME"
31："INTERVAL_TELEMETRY"
32："SERVO_LOW_THRESHOLD"
33："SERVO_HIGH_THRESHOLD"
34："SERVO_NEUTRAL"
35："SERVO_DEAD_BAND"
36："LOW_VOLTAGE_CUTOFF"
37："LOW_VOLTAGE_THRESHOLD"
38："RC_CAR_REVERSING"
39："USE_HALL_SENSORS"
40："SINE_MODE_RANGE"
41："BRAKE_STRENGTH"
46："ESC_PROTOCOL"
47："RAMP_SPEED"
48："STARTUP_MELODY"
176："DEVICE_ID"
188："DEVICE_KEY"

*/