import PropTypes from 'prop-types';
import React from 'react';

import Info from '../Info';

import './style.scss';

function Text({
  name,
  value,
  label,
  disabled,
  onChange,
  inSync,
  hint,
}) {
  return (
    <div className="textinput">
      <label>
        <div className="input-wrapper">
          <input
            disabled={disabled}
            name={name}
            onChange={onChange}
            type="text"
            value={value}
          />
        </div>
      </label>


      <Info
        hint={hint}
        inSync={inSync}
        label={label}
        name={name}
      />
    </div>
  );
}

Text.defaultProps = {
  disabled: false,
  hint: null,
  inSync: true,
  value: 0,
};

Text.propTypes = {
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  inSync: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};

export default Text;
