import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { 
  useCallback, 
  useState,
} from 'react';

import Overlay from '../Overlay';

import './style.scss';

function Presets({
  items,
  onClose,
  onUpdate,
}) {
  const { t } = useTranslation('common');
  
  const [currentPreset, setCurrentPreset] = useState(items.length > 0 ? items[0] : "");
  const [currentPresetDesc, setCurrentPresetDesc] = useState(items.length > 0 ? items[0].desc : "");

  const handleChange = useCallback((e) => {
    try{
      setCurrentPreset(items[e.target.value]);
      setCurrentPresetDesc(items[e.target.value].desc);
    }catch(e){
      console.log(e);
    }
  }, [items, setCurrentPreset]);


  
  const handleLoad = useCallback((e) => {
    onUpdate(currentPreset);
    onClose();
  }, [currentPreset, onUpdate, onClose]);


  const options = items.map((v, index) => (
    <option
      key={v.name}
      value={index}
    >
      {v.name}
    </option>
  ));


  return (
    <div className="presets">
      <Overlay
        headline={t('presetsHeader')}
        onClose={onClose}
      >
        <div className="presetDialog">
          <select
            className="dropdown__select"
            disabled={false}
            id="port"
            name={t('port')}
            onChange={handleChange}
            title={t('port')}
          >
            {options}
          </select>

          <div className="presetDesc">
            {currentPresetDesc}
          </div>

          <div className="button button--dark">
            <button
              disabled={false}
              onClick={handleLoad}
              type="button"
            >
              {t('loadPreset')}
            </button>
          </div>
        </div>
      </Overlay>
    </div>
  );
}

Presets.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired, 
};

export default Presets;
