import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useRef,
  useState,
} from 'react';
import ReactMarkdown from 'react-markdown';

import './style.scss';

function Install() {
  const { t } = useTranslation('common');

  const deferredPrompt = useRef(null);
  const [showInstall, setShowInstall]  = useState(false);

  window.addEventListener('beforeinstallprompt', (e) => {
    deferredPrompt.current = e;
    setShowInstall(true);
  });

  const handleInstallToHomescreen = useCallback(() => {
    if(deferredPrompt.current) {
      deferredPrompt.current.prompt();
    }
  }, [deferredPrompt]);

  return(
    <div className={`install-wrapper ${showInstall ? 'active' : ''}`}>
      <div className="install">
        <div className="description">
          <ReactMarkdown>
            {t('homeInstallLine1')}
          </ReactMarkdown>

          <ReactMarkdown>
            {t('homeInstallLine2')}
          </ReactMarkdown>
        </div>

        <div className="default-btn">
          <button
            onClick={handleInstallToHomescreen}
            type="button"
          >
            {t('addToHomeScreen')}
          </button>
        </div>
      </div>
    </div>
  );
}

function HomeColumnLeft() {
  const { t } = useTranslation('common');



  return(
    <div className="homemain text1">
      <div className="wrap">
        <div className="summary-section">

          <h2>
            {t('hf32UserManual')}
          </h2>

          <div>

            <a href="release/SuggestedSettings.pdf">
              {t('hf32UserManual')}
            </a>
            
            <br />

            <a href="release/HF32upgrading.pdf">
              Frash HF32 From AM32
            </a>

            <br />

            <a href="release/HF32_bootloader_PA2.hex">
              HF32 bootloader PA2
            </a>

            <br />

            <a href="release/HF32_bootloader_PB4.hex">
              HF32 bootloader PB4
            </a>

            <br />

            <a href="release/CH32_HF32_BOOTLOADER.hex">
              HF32 bootloader CH32
            </a>
            
            <br />

            <a href="release/HF32_BOOTLOADER_PB4_G0.hex">
              HF32 bootloader PB4 G071
            </a>

          </div>

        </div>

        <div className="summary-section">

          <h2>
            {t('homeDisclaimerHeader')}
          </h2>

          <div>

            <ReactMarkdown>
              The web application supports ESCs running  HF32 for At32, BLHeli for Atmel, BLHeli for SiLabs and BLHeli_S.
              **BLHeli FC passthrough** is the only interface currently supported.
              Please use the firmware under the specified condition by the product maker.
              Latest AT32 VCP driver can be downloaded from [here](https://www.arterychip.com/download/USB_Tool_Driver/VirtualCOM_DriverInstall_V1.0.31.zip).
            </ReactMarkdown>

            <ReactMarkdown>
              Contact admin@ hf32.org for support.
            </ReactMarkdown>

            <ReactMarkdown>
              Join our discord at https://discord.gg/pnqQSDpk . 
            </ReactMarkdown>
          </div>
        </div>

        <div className="summary-section">
          <h2>
            {t('homeTargets')}
          </h2>
          
          <div>
            <table className="targetsTable">
              <tr><th>MCU</th><th>Signal PIN</th><th>Target Name</th><th>Compatible Hardwares</th></tr>
              <tr><td>AT32F421</td><td>PB4</td><td>NeutronRC</td><td>NeutronRC 3-6S AIO<br />Tmotor 4-in-1 ESC 55A</td></tr>
              <tr><td></td><td>PA2</td><td>NeutronRC PA2</td><td>NeutronRC 3-6S ESC<br />NeutronRC Thunderbolt ESC</td></tr>
              <tr><td></td><td>PA2</td><td>NeutronRC IH PA2</td><td>NeutronRC 1-3S AIO</td></tr>
              <tr><td></td><td>PB4</td><td>CCRC</td><td>CCRC AIO<br />CCRC 4-in-1 ESC</td></tr>
              <tr><td></td><td>PB4</td><td>HAKRC AT4G</td><td>HAKRC AT4G</td></tr>
              <tr><td></td><td>PB4</td><td>DIATONE</td><td>DIATONE ESC</td></tr>
              <tr><td></td><td>PB4</td><td>GMRC</td><td>GreatMountain 4-in-1 ESC</td></tr>
              <tr><td></td><td>PA2</td><td>LYI32</td><td>LYI32 ESC</td></tr>
              <tr><td>CH32V203</td><td>PA0</td><td>NeutronRC V</td><td>NeutronRC V203</td></tr>
              <tr><td></td><td>PA0</td><td>LYI32 V</td><td>LYI32 V203</td></tr>
              <tr><td>STM32G071</td><td>PB4</td><td>NeutronRC G</td><td>NeutronRC G071</td></tr>
            </table>
          </div>
        </div>


        <div className="summary-section">
          <h2>
            {t('homeAttributionHeader')}
          </h2>
          
          <div>

            <ReactMarkdown>
              This HF32 configurator is forked from [esc-configurator](https://github.com/stylesuxx/esc-configurator). It is only modified to support the new ESC.
              The complete source code of HF32 configurator can be downloaded [here](release/hf32-source-code.zip). 
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
}


function Home({ onOpenMelodyEditor }) {
  const { t } = useTranslation('common');
  return (
    <div id="content">
      <div id="tab-landing">
        <div className="content_wrapper">
          <div className="content_top">
            <div
              align="center"
              className="logowrapper"
            >
              
              <div              >
                <span className="logo__span" />
                
                <span>
                  Welcome to HF32 Configurator.
                </span>
                
                <p>
                  HF32 is an ESC firmware for brushless motors in high performance multirotors.
                </p>
              </div>

            </div>
          </div>

          <div className="content_mid">
            <HomeColumnLeft />
          </div>
        </div>
      </div>
    </div>
  );
}
Home.propTypes = { onOpenMelodyEditor: PropTypes.func.isRequired };

export default Home;
